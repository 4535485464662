.wrapper {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .row {
    flex: 1;
    padding: 10px;
    margin: 10px;
    border: 1px solid black;
  }

  .row.dragged {
    filter: opacity(50%);
  }

  .row.target {
    background-color: lightgray;
  }

  .columnName {
    pointer-events: none;
    display: flex;
    justify-content: space-between;
  }

  .buttonContainer {
    pointer-events: all;
    display: flex;

    .actionButton {
      margin: .5em
    }
  }
}
