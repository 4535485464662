.wrapper {
  .nameWrapper {
    display: flex;
    justify-content: space-between;
  }

  .name {
    font-size: 20px;
  }

  svg {
    color: blue;
    cursor: pointer;
  }

  .buttonContainer {
    display: flex;

    .actionButton {
      margin: .5em
    }
  }
}
