.button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  & svg {
    width: 18px;
    height: 18px;
  }
  &:hover {
    & svg {
      fill: var(--colorPrimary-500);
    }
  }
}
